.App {
  max-width: 90%;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  padding: 20px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  position: relative;
}
 
.top{
  font-family: sans-serif;
   
}
h1{
    text-align: center;
    color: #222;
    font-family: sans-serif;
    font-size: 40px;
}
a {
  display: block;
  text-align: center;
  color: #222;
  margin-bottom: 10px;
  padding-bottom: 10px;
  font-family: sans-serif;
  font-size: 20px;
}
p {
  max-width: 900px;
  margin: 10px auto;
  text-align: center;
  font-family: sans-serif;
  font-size: 20px;
}
.ControlsBox {
  display: grid;
  grid-template-columns: 75% 15% 7%;
  gap: 25px;
}

.PanelsBox {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 20px;
}


.inputfile {
  border: 2px solid #ccc;
  border-radius: 3px;
  background-color: #ffffff;
  width: 100%;
  font-size: 20px;
}
.Button{
  white-space: nowrap;
  display: inline-block;
  border: 2px solid #ccc;
  border-radius: 5px;
  padding: 2px 15px;
  font-size: 16px;
  color: white;
  background-color:rgba(6, 49, 240, 0.842);
}
.Button:hover{
  background-image: linear-gradient(#ffffff, #d5d5d5 );
  color: black;
}
input#file-upload-button{
  height: 40px;
}
.graphcheck{
  min-height: 80px;
  max-height: 200px;
  padding: 10px;
  resize: none;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin-top: 10px;
  margin-bottom: 10px;

}
.highlight {
  min-height: 500px;
  max-height: 600px;
  padding: 10px;
  resize: none;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin: 0px
}

.Graph {
  min-height: 600px;
  max-height: 600px;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin: 0.5em 0px 1em 0px
}
#custom-button {
  padding: 10px;
  color: white;
  background-color: #009578;
  border: 1px solid #000;
  border-radius: 5px;
  cursor: pointer;
}

#custom-button:hover {
  background-color: #00b28f;
}

#custom-text {
  margin-left: 10px;
  font-family: sans-serif;
  color: #aaa;
}

.showChart{
  display:inline-block;
  width: 100%;
  height:50px;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin: 0px,20px,0px,20px;
  background-color:rgba(6, 49, 240, 0.842);
  white-space: nowrap;
  display: inline-block;
  border: 2px solid #ccc;
  border-radius: 5px;
  padding: 2px 15px;
  font-size: 16px;
  color: white;
  background-color:rgba(6, 49, 240, 0.842);

}

.showChart:hover{
  background-image: linear-gradient(#ffffff, #d5d5d5 );
  color: black;
}


.Lenged{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 20px;
}
.Graph_Lenged{
  grid-template-columns: 50%,50%;
}
.Code_Lenged{
  grid-template-columns: 50%,50%;
}
.note{
  display:inline-block;
  width: 50%;
  text-align: center;
}
#red{
  display:inline-block;
  width: 15px;
  height: 15px;
  text-align: center;
  background-color:red;
  border: 1px solid black;
  border-radius: 30px;
}
#white{
  display:inline-block;
  width: 15px;
  height: 15px;
  text-align: center;
  background-color:white;
  border: 1px solid black;
  border-radius: 30px;
}

#yellow{
  display:inline-block;
  width: 40px;
  height: 15px;
  text-align: center;
  background-color:#ffe7a4;
  border: 1px solid white;
}
#red_border{
  display:inline-block;
  width: 40px;
  height: 15px;
  text-align: center;
  background-color:white;
  border: 3px solid red;
}



.alert-main {
  animation: fadeIn ease 0.5s;
  width: 500px;
  margin-left: 30%;
  border-radius: 10px;
  text-align: center;
  position: fixed;
  background-color: white;
  min-height: 250px;
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 9999;
  top: 20vh;
  left: calc(50vw - 250);
  /* @media (max-width: $alert-width+$alert-extra-padding) {
    width: 90vw;
    left: 5vw;
  } */
}

.alert-header {
  font-size: 35px;
  font-weight: 600;
}

.alert-body {
  text-align: center;
  font-size: 30px;
}

.alert-button {
  width: 90px;
  height: 50px;
  display: flex;
  justify-content: center;
  text-decoration: none;
  color: white;
  border-radius: 40px;
  font-size: 17px;
  font-weight: 600;
  border-radius: 30px;
  align-items: center;
  position: relative;
  margin-top: 30px;
  margin-bottom: 20px;
}

.backdrop {
  animation: fadeIn ease 1s;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9998;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}