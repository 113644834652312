css
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


.App {
  max-width: 90%;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  padding: 20px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  position: relative;
}
 
.top{
  font-family: sans-serif;
   
}
h1{
    text-align: center;
    color: #222;
    font-family: sans-serif;
    font-size: 40px;
}
a {
  display: block;
  text-align: center;
  color: #222;
  margin-bottom: 10px;
  padding-bottom: 10px;
  font-family: sans-serif;
  font-size: 20px;
}
p {
  max-width: 900px;
  margin: 10px auto;
  text-align: center;
  font-family: sans-serif;
  font-size: 20px;
}
.ControlsBox {
  display: grid;
  grid-template-columns: 75% 15% 7%;
  grid-gap: 25px;
  gap: 25px;
}

.PanelsBox {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  grid-gap: 20px;
  gap: 20px;
}


.inputfile {
  border: 2px solid #ccc;
  border-radius: 3px;
  background-color: #ffffff;
  width: 100%;
  font-size: 20px;
}
.Button{
  white-space: nowrap;
  display: inline-block;
  border: 2px solid #ccc;
  border-radius: 5px;
  padding: 2px 15px;
  font-size: 16px;
  color: white;
  background-color:rgba(6, 49, 240, 0.842);
}
.Button:hover{
  background-image: linear-gradient(#ffffff, #d5d5d5 );
  color: black;
}
input#file-upload-button{
  height: 40px;
}
.graphcheck{
  min-height: 80px;
  max-height: 200px;
  padding: 10px;
  resize: none;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin-top: 10px;
  margin-bottom: 10px;

}
.highlight {
  min-height: 500px;
  max-height: 600px;
  padding: 10px;
  resize: none;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin: 0px
}

.Graph {
  min-height: 600px;
  max-height: 600px;
  background-color: #ffffff;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin: 0.5em 0px 1em 0px
}
#custom-button {
  padding: 10px;
  color: white;
  background-color: #009578;
  border: 1px solid #000;
  border-radius: 5px;
  cursor: pointer;
}

#custom-button:hover {
  background-color: #00b28f;
}

#custom-text {
  margin-left: 10px;
  font-family: sans-serif;
  color: #aaa;
}

.showChart{
  display:inline-block;
  width: 100%;
  height:50px;
  border: 1px solid #ccc;
  border-radius: 3px;
  margin: 0px,20px,0px,20px;
  background-color:rgba(6, 49, 240, 0.842);
  white-space: nowrap;
  display: inline-block;
  border: 2px solid #ccc;
  border-radius: 5px;
  padding: 2px 15px;
  font-size: 16px;
  color: white;
  background-color:rgba(6, 49, 240, 0.842);

}

.showChart:hover{
  background-image: linear-gradient(#ffffff, #d5d5d5 );
  color: black;
}


.Lenged{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  grid-gap: 20px;
  gap: 20px;
}
.Graph_Lenged{
  grid-template-columns: 50%,50%;
}
.Code_Lenged{
  grid-template-columns: 50%,50%;
}
.note{
  display:inline-block;
  width: 50%;
  text-align: center;
}
#red{
  display:inline-block;
  width: 15px;
  height: 15px;
  text-align: center;
  background-color:red;
  border: 1px solid black;
  border-radius: 30px;
}
#white{
  display:inline-block;
  width: 15px;
  height: 15px;
  text-align: center;
  background-color:white;
  border: 1px solid black;
  border-radius: 30px;
}

#yellow{
  display:inline-block;
  width: 40px;
  height: 15px;
  text-align: center;
  background-color:#ffe7a4;
  border: 1px solid white;
}
#red_border{
  display:inline-block;
  width: 40px;
  height: 15px;
  text-align: center;
  background-color:white;
  border: 3px solid red;
}



.alert-main {
  -webkit-animation: fadeIn ease 0.5s;
          animation: fadeIn ease 0.5s;
  width: 500px;
  margin-left: 30%;
  border-radius: 10px;
  text-align: center;
  position: fixed;
  background-color: white;
  min-height: 250px;
  display: flex;
  align-items: center;
  flex-direction: column;
  z-index: 9999;
  top: 20vh;
  left: calc(50vw - 250);
  /* @media (max-width: $alert-width+$alert-extra-padding) {
    width: 90vw;
    left: 5vw;
  } */
}

.alert-header {
  font-size: 35px;
  font-weight: 600;
}

.alert-body {
  text-align: center;
  font-size: 30px;
}

.alert-button {
  width: 90px;
  height: 50px;
  display: flex;
  justify-content: center;
  text-decoration: none;
  color: white;
  border-radius: 40px;
  font-size: 17px;
  font-weight: 600;
  border-radius: 30px;
  align-items: center;
  position: relative;
  margin-top: 30px;
  margin-bottom: 20px;
}

.backdrop {
  -webkit-animation: fadeIn ease 1s;
          animation: fadeIn ease 1s;
  background-color: rgba(0, 0, 0, 0.6);
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9998;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.comment{
    border: 1px solid #ccc;
    border-radius: 3px;
    padding-left: 5px;

}
.col_6{
    display:inline-block;
    width: 16.6%;
    font-size:medium;
}
.col_5_2{
    display:inline-block;
    width: 25%;
}
.col_5_1{
    display:inline-block;
    width: 16.6%;
}
.line_5 {
    grid-template-columns: 16.6% 16.6% 16.6% 25% 25%;
}
.line_6{
    grid-template-columns: 16.6% 16.6% 16.6% 16.6% 16.6%;
}
#entrypoint{
    display:inline-block;
    width: 10px;
    height: 10px;
    text-align: center;
    background-color:#0E38E3;
    border: 1px solid #ccc;
    border-radius: 30px;
}
#new_var{
    display:inline-block;
    width: 10px;
    height: 10px;
    text-align: center;
    background-color:#1c71c7;
    border: 1px solid #ccc;
    border-radius: 30px;
}
#expression{
    display:inline-block;
    width: 10px;
    height: 10px;
    text-align: center;
    background-color:#2aaaaa;
    border: 1px solid #ccc;
    border-radius: 30px;
}
#if{
    display:inline-block;
    width: 10px;
    height: 10px;
    text-align: center;
    background-color:#38e38e;
    border: 1px solid #ccc;
    border-radius: 30px;
}

#endif{
    display:inline-block;
    width: 10px;
    height: 10px;
    text-align: center;
    background-color:#471c71;
    border: 1px solid #ccc;
    border-radius: 30px;
}
#func{
    display:inline-block;
    width: 10px;
    height: 10px;
    text-align: center;
    background-color:#555555;
    border: 1px solid #ccc;
    border-radius: 30px;
}
#other{
    display:inline-block;
    width: 10px;
    height: 10px;
    text-align: center;
    background-color:#638e38;
    border: 1px solid #ccc;
    border-radius: 30px;
}
#throw{
    display:inline-block;
    width: 10px;
    height: 10px;
    text-align: center;
    background-color:#71c71c;
    border: 1px solid #ccc;
    border-radius: 30px;
}
#_{
    display:inline-block;
    width: 10px;
    height: 10px;
    text-align: center;
    background-color:#7fffff;
    border: 1px solid #ccc;
    border-radius: 30px;
}

#return{
    display:inline-block;
    width: 10px;
    height: 10px;
    text-align: center;
    background-color:#8e38e3;
    border: 1px solid #ccc;
    border-radius: 30px;
}
#inline{
    display:inline-block;
    width: 10px;
    height: 10px;
    text-align: center;
    background-color:#9c71c6;
    border: 1px solid #ccc;
    border-radius: 30px;
}
#bg_loop{
    display:inline-block;
    width: 10px;
    height: 10px;
    text-align: center;
    background-color:#aaaaaa;
    border: 1px solid #ccc;
    border-radius: 30px;
}
#end_loop{
    display:inline-block;
    width: 10px;
    height: 10px;
    text-align: center;
    background-color:#b8e38d;
    border: 1px solid #ccc;
    border-radius: 30px;
}
#ifloop{
    display:inline-block;
    width: 10px;
    height: 10px;
    text-align: center;
    background-color:#c71c71;
    border: 1px solid #ccc;
    border-radius: 30px;
}
#continue{
    display:inline-block;
    width: 10px;
    height: 10px;
    text-align: center;
    background-color:#d5554d;
    border: 1px solid #ccc;
    border-radius: 30px;
}
#contract_func{
    display:inline-block;
    width: 10px;
    height: 10px;
    text-align: center;
    background-color:#e38e30;
    border: 1px solid #ccc;
    border-radius: 30px;
}
#fallback_func{
    display:inline-block;
    width: 10px;
    height: 10px;
    text-align: center;
    background-color:#f1c713;
    border: 1px solid #ccc;
    border-radius: 30px;
}



.Access_control_green{
    display:inline-block;
      width: 120px;
      height:30px;
      border: 1px solid #ccc;
      background-color:rgba(6, 240, 96, 0.842);
      border-radius: 3px;
      margin: 10px 40px 20px 0px;
  }
  .Arithmetic_green{
    display:inline-block;
      width: 110px;
      height:30px;
      border: 1px solid #ccc;
      border-radius: 3px;
      margin: 10px 40px 20px 0px;
      background-color:rgba(6, 240, 96, 0.842);
  }
  .Denial_of_service_green{
    display:inline-block;
      width: 120px;
      height:30px;
      background-color:#ADD8E6;
      border: 1px solid #ccc;
      border-radius: 3px;
      margin: 10px 40px 20px 0px;
      background-color:rgba(6, 240, 96, 0.842);
  }
  .Front_running_green{
    display:inline-block;
      width: 120px;
      height:30px;
      border: 1px solid #ccc;
      border-radius: 3px;
       margin: 10px 40px 20px 0px;
      background-color:rgba(6, 240, 96, 0.842);
  }
  .Reentrancy_green{
    display:inline-block;
      width: 120px;
      height:30px;
      border: 1px solid #ccc;
      border-radius: 3px;
       margin: 10px 40px 20px 0px;
      background-color:rgba(6, 240, 96, 0.842);
  }
  .Time_manipulation_green{
    display:inline-block;
    width: 140px;
    height:30px;
    border: 1px solid #ccc;
    border-radius: 3px;
     margin: 10px 40px 20px 0px;
    background-color:rgba(6, 240, 96, 0.842);
    
  }
  .Unchecked_low_level_calls_green{
    display:inline-block;
    width: 200px;
    height:30px;
    border: 1px solid #ccc;
    border-radius: 3px;
    margin: 10px,20px,0px,20px;
    background-color:rgba(6, 240, 96, 0.842);

  }
  .Access_control_green:hover{
    background-image: linear-gradient(#ffffff, #d5d5d5 );
    color: black;
  }
  .Arithmetic_green:hover{
    background-image: linear-gradient(#ffffff, #d5d5d5 );
    color: black;
  }
  .Denial_of_service_green:hover{
    background-image: linear-gradient(#ffffff, #d5d5d5 );
    color: black;
  }
  .Front_running_green:hover{
    background-image: linear-gradient(#ffffff, #d5d5d5 );
    color: black;
  }
  .Reentrancy_green:hover{
    background-image: linear-gradient(#ffffff, #d5d5d5 );
    color: black;
  }
  .Time_manipulation_green:hover{
    background-image: linear-gradient(#ffffff, #d5d5d5 );
    color: black;
  }
  .Unchecked_low_level_calls_green:hover{
    background-image: linear-gradient(#ffffff, #d5d5d5 );
    color: black;
  }

  .Access_control_red{
    display:inline-block;
      width: 120px;
      height:30px;
      border: 1px solid #ccc;
      background-color:rgba(214, 12, 39, 0.671);
      border-radius: 3px;
       margin: 10px 40px 20px 0px;
  }
  .Arithmetic_red{
    display:inline-block;
      width: 110px;
      height:30px;
      border: 1px solid #ccc;
      border-radius: 3px;
       margin: 10px 40px 20px 0px;
      background-color:rgba(214, 12, 39, 0.671);
  }
  .Denial_of_service_red{
    display:inline-block;
      width: 130px;
      height:30px;
      background-color:#ADD8E6;
      border: 1px solid #ccc;
      border-radius: 3px;
       margin: 10px 40px 20px 0px;
      background-color:rgba(214, 12, 39, 0.671);
  }
  .Front_running_red{
    display:inline-block;
      width: 120px;
      height:30px;
      border: 1px solid #ccc;
      border-radius: 3px;
       margin: 10px 40px 20px 0px;
      background-color:rgba(214, 12, 39, 0.671);
  }
  .Reentrancy_red{
    display:inline-block;
      width: 120px;
      height:30px;
      border: 1px solid #ccc;
      border-radius: 3px;
       margin: 10px 40px 20px 0px;
      background-color:rgba(214, 12, 39, 0.671);
  }
  .Time_manipulation_red{
    display:inline-block;
    width: 140px;
    height:30px;
    border: 1px solid #ccc;
    border-radius: 3px;
    margin: 10px 40px 20px 0px;
    background-color:rgba(214, 12, 39, 0.671);
    
  }
  .Unchecked_low_level_calls_red{
    display:inline-block;
    width: 200px;
    height:30px;
    border: 1px solid #ccc;
    border-radius: 3px;
    margin: 10px 0px 20px 0px;
    background-color:rgba(214, 12, 39, 0.671);

  }
  .Access_control_red:hover{
    background-image: linear-gradient(#ffffff, #d5d5d5 );
    color: black;
  }
  .Arithmetic_red:hover{
    background-image: linear-gradient(#ffffff, #d5d5d5 );
    color: black;
  }
  .Denial_of_service_red:hover{
    background-image: linear-gradient(#ffffff, #d5d5d5 );
    color: black;
  }
  .Front_running_red:hover{
    background-image: linear-gradient(#ffffff, #d5d5d5 );
    color: black;
  }
  .Reentrancy_red:hover{
    background-image: linear-gradient(#ffffff, #d5d5d5 );
    color: black;
  }
  .Time_manipulation_red:hover{
    background-image: linear-gradient(#ffffff, #d5d5d5 );
    color: black;
  }
  .Unchecked_low_level_calls_red:hover{
    background-image: linear-gradient(#ffffff, #d5d5d5 );
    color: black;
  }

#red_legend{
    display:inline-block;
    width: 50px;
    height: 15px;
    text-align: center;
    background-color:rgba(214, 12, 39, 0.671);
    border: 1px solid #ccc;
    border-radius: 3px;
   
}
#green_legend{
    display:inline-block;
    width: 50px;
    height: 15px;
    text-align: center;
    background-color:rgba(6, 240, 96, 0.842);
    border: 1px solid #ccc;
    border-radius: 3px;
}
.legend_button{
    grid-template-columns: 50%,50%;
}
.note{
    display:inline-block;
    width: 50%;
    text-align: center;
}
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #333;
    color: #fff;
    padding: 1rem;
  }
  
  .navbar-left .logo {
    font-size: 1.5rem;
    font-weight: bold;
    color: #fff;
    text-decoration: none;
    padding-top: 1.4rem;
  }
  
  .navbar-center .nav-links {
    list-style-type: none;
    display: flex;
    margin: 0;
    padding: 0;
  }
  
  .navbar-center .nav-links li {
    margin-right: 2rem;
  }
  
  .navbar-center .nav-links a {
    color: #fff;
    text-decoration: none;
  }
  
  .navbar-right {
    display: flex;
    align-items: center;
  }
  
  .navbar-right .cart-icon,
  .navbar-right .user-icon {
    color: #fff;
    text-decoration: none;
    margin-left: 1rem;
    position: relative;
  }
  
  .navbar-right .cart-count {
    background-color: #f44336;
    color: #fff;
    border-radius: 50%;
    padding: 0.2rem 0.5rem;
    font-size: 0.8rem;
    position: absolute;
    top: -0.5rem;
    right: -0.5rem;
  }
  
